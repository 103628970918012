import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import { Link } from "react-router-dom";

const Header = ({ showMenuItems }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleRef = useRef(null);

  const handleToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = event => {
    if (isMenuOpen && toggleRef.current && !toggleRef.current.contains(event.target)) {
      toggleRef.current.click();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={require("img/logo_white.png")}
              alt="logotipo da aplicação iCyclin"
              width="auto"
              height="30"
              className="d-inline-block align-top me-2"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="app-navbar" ref={toggleRef} onClick={handleToggle} />
          <Navbar.Collapse id="app-navbar" ref={menuRef}>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/private/search">
                <img src={require("img/icons/Icone_Interacoes_Farmaco.png")} alt="" />
                Interações por fármaco
              </Nav.Link>
              <Nav.Link as={Link} to="/private/groups">
                <img src={require("img/icons/Icone_Grupos_Farmacologicos.png")} alt="" />
                Grupos farmacológicos
              </Nav.Link>
              <Nav.Link as={Link} to="/private/patient">
                <img src={require("img/icons/Icone_Perfil_Doente.png")} alt="" />
                Perfil de doente
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link as={Link} to="/help">
                <img src={require("img/icons/Icone_Como_Funciona.png")} alt="" />
                Como funciona
              </Nav.Link>
              <Nav.Link as={Link} to="/private/references">
                <img src={require("img/icons/Icone_Referencias_Bibliograficas.png")} alt="" />
                Referências
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                <img src={require("img/icons/Icone_Sobre_a_SPS.png")} alt="" />
                Sobre
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
