import React from "react";
import { Col, Row } from "react-bootstrap";

import Sponsors from "components/sponsors";
import { DDILegendWithText } from "components/ddi_legend";

const About = () => (
  <>
    <div className="text-center mt-5">
      <h2>Sobre a iCyclin</h2>
    </div>
    <div className="mx-lg-5 text-justify">
      <p>
        As intera&ccedil;&otilde;es medicamentosas s&atilde;o uma considera&ccedil;&atilde;o importante para os doentes
        submetidos &agrave; terapia oncol&oacute;gica. Muitas vezes, os doentes recebem v&aacute;rios medicamentos como
        parte do seu tratamento oncol&oacute;gico, bem como terapias adicionais para controlar comorbidades e efeitos
        colaterais do tratamento.
      </p>
      <p>
        <strong>
          As intera&ccedil;&otilde;es medicamentosas dos inibidores de CDK s&atilde;o relevantes por v&aacute;rias
          raz&otilde;es:
        </strong>
      </p>
      <ul>
        <li>
          <strong>Seguran&ccedil;a do Doente:</strong> As intera&ccedil;&otilde;es medicamentosas podem afetar a
          seguran&ccedil;a do Doente, levando a efeitos adversos ou a diminui&ccedil;&atilde;o da efic&aacute;cia do
          tratamento.
        </li>
        <li>
          <strong>Efic&aacute;cia do Tratamento:</strong> Certos medicamentos podem influenciar a absor&ccedil;&atilde;o
          e a elimina&ccedil;&atilde;o dos inibidores de CDK, afetando a sua concentra&ccedil;&atilde;o efetiva.
        </li>
        <li>
          <strong>Toxicidade:</strong> Algumas intera&ccedil;&otilde;es podem aumentar o risco de toxicidade dos
          inibidores de CDK, sendo fundamental entender como os medicamentos interagem entre si.
        </li>
        <li>
          <strong>Polimedica&ccedil;&atilde;o:</strong> A polimedica&ccedil;&atilde;o aumenta o risco de
          intera&ccedil;&otilde;es medicamentosas, o que pode afetar a efic&aacute;cia dos tratamentos prescritos,
          causar efeitos adversos indesejados ou influenciar a toxicidade. Os doentes com cancro, muitas vezes,
          est&atilde;o em tratamento em simult&acirc;neo para outras condi&ccedil;&otilde;es m&eacute;dicas. Neste
          sentido, a gest&atilde;o das v&aacute;rias terap&ecirc;uticas &eacute; fulcral para evitar
          complica&ccedil;&otilde;es decorrentes das intera&ccedil;&otilde;es medicamentosas.
        </li>
        <li>
          <strong>Personaliza&ccedil;&atilde;o do tratamento:</strong> Compreender as intera&ccedil;&otilde;es
          medicamentosas, permite aos profissionais de sa&uacute;de, personalizar o tratamento com os inibidores de CDK
          com base nos medicamentos concomitantes do doente, garantindo que os doentes recebem o m&aacute;ximo
          benef&iacute;cio do tratamento que lhes foi prescrito.
        </li>
      </ul>
      <p>
        Os profissionais de sa&uacute;de t&ecirc;m de rever cuidadosamente todos medicamentos de cada doente para
        identificar potenciais intera&ccedil;&otilde;es que possam ocorrer entre os inibidores de CDK e outros
        medicamentos. Por esse motivo, surgiu a necessidade de desenvolver uma ferramenta que permita apoiar os
        m&eacute;dicos na avalia&ccedil;&atilde;o destas intera&ccedil;&otilde;es de uma forma r&aacute;pida, segura e
        eficiente.
      </p>
      <p>
        A iCyclin, pretende ser uma plataforma intuitiva e acess&iacute;vel para utiliza&ccedil;&atilde;o m&eacute;dica
        com o prop&oacute;sito de fornecer informa&ccedil;&otilde;es atualizadas sobre intera&ccedil;&otilde;es
        medicamentosas relacionadas aos inibidores de CDK4/6, como <em>palbociclib, ribociclib e abemaciclib</em>.
      </p>
      <p>
        Pretende-se que esta aplica&ccedil;&atilde;o sirva de apoio ao trabalho m&eacute;dico, ajudando na tomada de
        decis&otilde;es sobre a terapia mais adequada em doentes com Cancro da Mama.
      </p>
      <DDILegendWithText />
    </div>
    <hr className="m-5 mb-0"/>
    <Sponsors/>
    <hr className="m-5 mb-0"/>
    <div className="mt-5 ">
      <h2>A Sociedade Portuguesa de Senologia</h2>
    </div>
    <div className="mx-lg-5 text-justify">
      <Row>
        <Col xs={12} md={4} lg={3}>
          <a href="https://www.spsenologia.pt/" target="_blank" rel="noreferrer noopener">
            <img src={require("img/sps_logo.png")} alt="logo SPS" style={{ float: "left" }} />
          </a>
        </Col>
        <Col className="ps-md-5">
          <p>
            A Sociedade Portuguesa de Senologia (SPS) &eacute; uma Associa&ccedil;&atilde;o Cient&iacute;fica e
            Cultural, sem fins lucrativos, dedicada ao estudo e promo&ccedil;&atilde;o da investiga&ccedil;&atilde;o no
            campo da Senologia em Portugal.
          </p>
          <p>
            A senologia &eacute; uma &aacute;rea m&eacute;dica que se concentra no estudo das doen&ccedil;as da mama.
          </p>
        </Col>
      </Row>

      <p>
        <strong>A SPS tem como finalidades:</strong>
      </p>
      <ul>
        <li>
          Desenvolver a n&iacute;vel nacional o conhecimento referente &agrave; gl&acirc;ndula mam&aacute;ria, em
          Medicina e em Biologia, com o objetivo de fazer progredir na fisiologia, preven&ccedil;&atilde;o e
          diagn&oacute;stico, tratamento e reabilita&ccedil;&atilde;o da patologia da mama;
        </li>
        <li>
          Desenvolver a investiga&ccedil;&atilde;o b&aacute;sica e cl&iacute;nica, incentivar trabalhos e
          publica&ccedil;&otilde;es, organizar reuni&otilde;es e congressos, promover o ensino e coordenar as diversas
          disciplinas interessadas;
        </li>
        <li>
          Assegurar a sua participa&ccedil;&atilde;o em Sociedades Nacionais e Internacionais que tenham objetivos
          id&ecirc;nticos;
        </li>
        <li>
          Estabelecer programa de acredita&ccedil;&atilde;o e de certifica&ccedil;&atilde;o de unidades de sa&uacute;de
          e de profissionais que atuem na &aacute;rea da senologia;
        </li>
        <li>Organizar e ministrar cursos de p&oacute;s-gradua&ccedil;&atilde;o na &aacute;rea da senologia.</li>
      </ul>

      <p>
        <strong>
          A Sociedade Portuguesa de Senologia (SPS) est&aacute; constantemente empenhada em melhorar o cuidado
          m&eacute;dico para doentes com Cancro da Mama. A cria&ccedil;&atilde;o da aplica&ccedil;&atilde;o iCyclin
          demonstra o compromisso da SPS em fornecer ferramentas avan&ccedil;adas para apoiar os m&eacute;dicos na
          tomada de decis&otilde;es sobre a terapia mais apropriada para os seus doentes.
        </strong>
      </p>
      <p>
        Saiba mais sobre a{" "}
        <a href="https://www.spsenologia.pt/" target="_blank" rel="noreferrer noopener">
          Sociedade Portuguesa de Senologia
        </a>
        .
      </p>
    </div>
    
  </>
);

export default About;
