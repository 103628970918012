import React from "react";
import ReactDOM from "react-dom/client";

// import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./index.css";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { getSessionId } from "util";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

console.log("App starting - Session " + getSessionId());

const onNewVersionAvailable = registration => {
  console.log("Novo update da app. A confirmar reload.", registration);
  window.alert(
    "Existe uma nova versão da aplicação disponível.\nVamos recarregar a mesma para que possa tirar partido imediatamente das mais recentes melhorias."
  );
  // More info on PWA reload here: https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
  setTimeout(() => {
    window.location.reload(true);
  }, 1000);
  registration.waiting.postMessage({ type: "SKIP_WAITING" });
};
const onServiceWorkerRegistration = registration => {
  registration.waiting.postMessage({ type: "SKIP_WAITING" });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({ onUpdate: onNewVersionAvailable, onSuccess: onServiceWorkerRegistration });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
