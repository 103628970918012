import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DDIIcon = ({ value }) => {
  const getIconClass = val => {
    switch (val) {
      case 0:
        return "bi-diamond-fill";
      case 1:
        return "bi-triangle-fill";
      case 2:
        return "bi-square-fill";
      case 3:
        return "bi-circle-fill";
      default:
        return "bi-circle-fill";
    }
  };

  return (
    <OverlayTrigger
      popperConfig={{ modifiers: { preventOverflow: { enabled: "false" } } }}
      overlay={
        <Tooltip style={{ position: "fixed" }}>
          {value === 0
            ? "Interação Não Esperada"
            : value === 1
            ? "Interação de Baixo Potencial"
            : value === 2
            ? "Interação Potencial"
            : value === 3
            ? "Não Coadministrar"
            : ""}
        </Tooltip>
      }
    >
      <i className={"bi " + getIconClass(value) + " ddi-score-" + value} />
    </OverlayTrigger>
  );
};

export default DDIIcon;
