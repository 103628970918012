export const getSessionId = () => {
  let sessionId = localStorage.getItem("sessionId");
  if (!sessionId) {
    sessionId = crypto.randomUUID();
    localStorage.setItem("sessionId", sessionId);
  }
  return sessionId;
};

export const HCP_TYPE = {
  Doctor: "doctor",
  Nurse: "nurse",
  Pharmacist: "pharmacist",
  Other: "other",
};

export function isClient() {
  return typeof window === "object";
}

// Detects if device is on iOS
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
// Detects if device is in standalone mode
export const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone;
export const isInstalled = async () => {
  const androidInstalled = await navigator.getInstalledRelatedApps();
  return isInStandaloneMode() || androidInstalled?.length > 0;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);



/********** Number Utilities */
export const intOrDefault = (value, defaultVal = 0) => {
  const intVal = parseInt(value);
  return isNaN(intVal) ? defaultVal : intVal;
};

/////// Date functions
export function getDatePart(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
export function dateValuesFromFieds(fldYear, fldMonth, fldDay) {
  // Supports field values that come in the {value, label} format from dropdowns
  return {
    year: fldYear ? fldYear.value ?? fldYear : 0,
    month: fldMonth ? fldMonth.value ?? fldMonth : 0,
    day: fldDay ? fldDay.value ?? fldDay : 0,
  };
}
export function dateStringFromFields(fldYear, fldMonth, fldDay, separator) {
  const dts = dateValuesFromFieds(fldYear, fldMonth, fldDay);
  return (
    dts.year.toString().padStart(4, "0") +
    "-" +
    dts.month.toString().padStart(2, "0") +
    "-" +
    dts.day.toString().padStart(2, "0")
  );
}
export function dateFromFields(fldYear, fldMonth, fldDay) {
  try {
    const parseDate = new Date(Date.parse(dateStringFromFields(fldYear, fldMonth, fldDay) + "T00:00:00.000Z"));
    if (!parseDate) return null;
    if (parseDate.toString() === "Invalid Date") return null;
    return parseDate;
  } catch (err) {
    return null;
  }
}

export function string2Date(value, defaultDate = null) {
  if (value === null || value === undefined || value === "") return defaultDate;
  try {
    const parseDate = new Date(value);
    if (!parseDate) return null;
    if (parseDate.toString() === "Invalid Date") return null;
    return parseDate;
  } catch (err) {
    return null;
  }
}

// export const formatDate = (date, format = "DD.MM.YYYY") => formatDateTime(date, format)
// export const formatDateTime = (date, format = "DD.MM.YYYY HH:mm:ss") => {
//   moment.locale("pt")
//   return date ? moment(new Date(date)).format(format) : "--"
// }

export function binBool(value) {
  return value ? 1 : 0;
}
export function string2Bool(value, defaultVal = false) {
  if (value === null || value === undefined || value === "") return defaultVal;
  return value === "true" || value === "1" || value.toLowerCase() === "sim" || value.toLowerCase() === "yes";
}

/********************
 * Object manipulation utils
 */
export const isObject = val => typeof val === "object" && !Array.isArray(val);

// 2 levels deep only
export function flattenDataObject(obj) {
  if (!obj) return {};
  // console.log("Flattening", obj)
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      // For lookup values ({value,label})
      if (obj[key].value) {
        obj[key] = obj[key].value;
        // Other objects we use the standard toString
      } else if (isObject(obj[key])) {
        let sObj = obj[key];
        Object.keys(sObj).forEach(skey => {
          if (sObj[skey]) {
            obj[key + "_" + skey] = sObj[skey].toString();
          }
        });
        //obj[key] = obj[key].toString()
        //console.log("One object is unknown: " + key, obj[key])
      }
      // if null, we remove the property
    } else {
      delete obj[key];
    }
  });
  return obj;
}
export function deNull(obj) {
  if (!obj) return {};
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      if (isObject(obj[key])) {
        obj[key] = deNull(obj[key]);
      }
    } else {
      obj[key] = null;
    }
  });
  return obj;
}
export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
}
export function nullifyEmptyString(obj) {
  for (var propName in obj) {
    if (obj[propName] === "") {
      obj[propName] = null;
    }
  }
}

export function numberArray(start, end) {
  let a = [];
  for (let i = start; i <= end; i++) {
    a.push(i);
  }
  return a;
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export function rgbToHex(r, g, b) {
  const componentToHex = c => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const IsNullOrUndefined = value => {
  if (typeof value === "undefined") return true;
  switch (value) {
    case null:
      return true;
    case undefined:
      return true;
    case "null":
      return true;
    case "undefined":
      return true;
    default:
      return false;
  }
};

/******************************
 * String Utils
 */

export const replaceAll = (string, search, replace) => string.split(search).join(replace);

export const stringIsNullOrEmpty = stringVal => {
  if (!stringVal) return true;
  if (typeof stringVal === "string") {
    if (stringVal.trim().length === 0) return true;
  }
  return false;
};

export const isEmptyOrNullString = value => {
  return IsNullOrUndefined(value) || stringIsNullOrEmpty(value);
};
export const sanitizeEmptyOrNullString = value => {
  if (IsNullOrUndefined(value) || stringIsNullOrEmpty(value)) return null;
  return value;
};
export const emptyString = isEmptyOrNullString;
export const isEmptyString = isEmptyOrNullString;

export function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}

export function splitName(name) {
  if (!name)
    return {
      name: "",
      firstName: "",
      lastName: "",
    };
  name = name.trim();
  const lastSpace = name.lastIndexOf(" ");
  const lastName = lastSpace > 0 ? name.slice(lastSpace + 1) : ""; // > 0 will catch "J Abreu"
  const firstName = name.slice(0, lastSpace > 0 ? lastSpace : name.length);
  return {
    name,
    firstName,
    lastName,
  };
}

// export function titleCase(string) {
//   var sentence = string.toLowerCase().split(" ")
//   for (var i = 0; i < sentence.length; i++) {
//     sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
//   }

//   return sentence
// }
export function titleCase(str) {
  if (!str) return str;
  return str
    .toString()
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .trim();
}

// export async function parsePostFormData (req) {

//   return await new Promise((resolve, reject) => {
//     const form = formidable({ multiples: true })

//     form.parse(req, (err, fields, files) => {
//       if (err) {
//         return reject(err)
//       }
//       resolve(fields)
//     })
//   })

// }

/******************************
 * Date Utils
 */
export function getCurrentDateTime(date) {
  const month = date.getUTCMonth() + 1; //months from 1-12
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  const currentDate = year + "-" + month + "-" + day;
  const currentTime = date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();

  return currentDate + " às " + currentTime;
}

export function dateFormat(date) {
  const dateObj = new Date(date);
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  const currentTime = dateObj.getUTCHours() + "_" + dateObj.getUTCMinutes() + "_" + dateObj.getUTCSeconds();

  const finalDate = year + "-" + month + "-" + day + " " + currentTime;
  return finalDate;
}

export function dateWithoutTime(date) {
  const dateObj = new Date(date);
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  const finalDate = year + "-" + month + "-" + day;
  return finalDate;
}

export function dateToSQL(date) {
  const dateObj = new Date(date);
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  const currentTime = dateObj.getUTCHours() + ":" + dateObj.getUTCMinutes() + ":" + dateObj.getUTCSeconds();

  const finalDate = year + "-" + month + "-" + day + " " + currentTime;
  return finalDate;
}

export function joinDate(date) {
  const dateObj = new Date(date);

  const year = dateObj.getUTCFullYear();
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();

  const _month = month < 10 ? `0${month}` : month;
  const _day = day < 10 ? `0${day}` : day;

  return `${year}${_month}${_day}`;
}

export function convertJoinedDates(joinedDateRange) {
  /**
   * joinedDateRange (ex) = Listagem Sanofi 20201110s_20201118
   * joinedDateRange (ex) = Lista OneKeys_Cognipharma_20200907_20200913.csv
   * */

  if (stringIsNullOrEmpty(joinedDateRange)) throw Error("joinedDateRange is required");

  let fromDate = "";
  let toDate = "";

  /** Get fromDate from joinedDateRange */
  for (let i = 0; i <= joinedDateRange.length; i++) {
    if (i === joinedDateRange.lastIndexOf("_")) break;
    if (Number(joinedDateRange[i]) || joinedDateRange[i] === "0") {
      fromDate += joinedDateRange[i];
    }
  }

  /** Tranform joinedDateRange to catch toDate */
  joinedDateRange = joinedDateRange.slice(joinedDateRange.lastIndexOf("_") + 1, joinedDateRange.length);

  /** Get toDate from joinedDateRange */
  for (let i = 0; i <= joinedDateRange.length; i++) {
    if (joinedDateRange[i] === ".") break;
    if (Number(joinedDateRange[i]) || joinedDateRange[i] === "0") {
      toDate += joinedDateRange[i];
    }
  }

  /** Tranform into valid Dates */
  let finalFromDate = "";
  let finalToDate = "";

  for (let i = 0; i <= fromDate.length - 1; i++) {
    if (i === 4) {
      finalFromDate += "-";
      finalToDate += "-";
    }

    if (i === 6) {
      finalFromDate += "-";
      finalToDate += "-";
    }

    finalFromDate += fromDate[i];
    finalToDate += toDate[i];
  }

  //return { fromDate, toDate }
  return { fromDate: finalFromDate, toDate: finalToDate };
}

export function getYesterdayDate() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(23);
  yesterday.setMinutes(59);
  yesterday.setSeconds(59);
  yesterday.setMilliseconds(999);
  return yesterday;
}

export function getNextDay(date) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(999);
  return newDate;
}

/***************** Epermission Form Text Utils */
export const transformHCPName = data => {
  if (!data.job || !data.job.value || !data.firstName || !data.lastName) return "";

  let salutation;
  if (data.job.value === HCP_TYPE.Nurse) {
    salutation = "Enf(a).";
  } else {
    salutation = "Dr(a).";
  }

  const firstName = data.firstName.split(" ")[0];
  const lastNameArr = data.lastName.split(" ");
  const lastName = lastNameArr[lastNameArr.length - 1];

  return `${salutation} ${firstName} ${lastName}`;
};

export const detectInternetExplorer = userAgent => {
  try {
    if (stringIsNullOrEmpty(userAgent)) return false;
    var msie = userAgent.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return true;
    }
    var trident = userAgent.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      return true;
    }
    /* var edge = userAgent.indexOf('Edge/');
    if (edge > 0) {
      // Edge => return version number
      return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
    } */
    // other browser
    return false;
  } catch {
    return false;
  }
};
