import { HCP_TYPE, stringIsNullOrEmpty } from "util";

const COGNI_PLATFORM_API_KEY = "6c6d03e6-9446-4f77-befe-d3c19766c051";
const COGNI_PLATFORM_URL = "https://platform.cognipharma.com";
const COGNI_PLATFORM_APPNAME = "ICYCLIN";

export async function CognipharmaHCPLookup(hcpType, hcpName, hcpLicenseNumber) {
  const country = "pt";

  if (hcpType === HCP_TYPE.Other) {
    return {
      error: {
        message:
          "Não conseguimos validar automaticamente que é um Profissional de Saúde. Apenas é possível validar Médicos, Enfermeiros e Farmacêuticos, registados na respetiva Ordem, em Portugal.",
      },
    };
  }

  if (hcpType === HCP_TYPE.Doctor) {
    if (stringIsNullOrEmpty(hcpName) && stringIsNullOrEmpty(hcpLicenseNumber)) {
      return { error: { message: "Please fill in HCP Name or License Id" } };
    }
  } else if (stringIsNullOrEmpty(hcpName)) {
    return { error: { message: "Please fill in HCP Name" } };
  }

  if (stringIsNullOrEmpty(hcpName) && stringIsNullOrEmpty(hcpLicenseNumber)) {
    return { error: { message: "Please fill in HCP Name or License Id" } };
  }

  if (!stringIsNullOrEmpty(hcpLicenseNumber) && isNaN(Number(hcpLicenseNumber))) {
    return { error: { message: "Please fill in a valid license number" } };
  }

  try {
    const url =
      COGNI_PLATFORM_URL +
      `/api/cogni-id/hcps/lookup?job=${hcpType}&licenseNumber=${hcpLicenseNumber}&name=${encodeURIComponent(
        hcpName
      )}&country=${country}`;
    console.log(url);
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "api-key": COGNI_PLATFORM_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    const data = await res.json();

    if (res.status !== 200) {
      if (data.error) return { error: data.error };
      if (data.message) return { error: { message: data.message } };
    }

    return data;
  } catch (err) {
    console.error(err);
    return {
      error: {
        message:
          "Impossível validar este Profissional de Saúde. \n" +
          err.message +
          "\nPor favor contacte support@cognipharma.com",
      },
    };
  }
}

export async function user_SendToCognipharmaPlatform(data, userId) {
  data.id = userId;
  const cogniPlatformEndpoint = COGNI_PLATFORM_URL + "/" + COGNI_PLATFORM_APPNAME + "/id";
  try {
    //const response = await api.post(cogniPlatformEndpoint, data)
    const response = await fetch(cogniPlatformEndpoint, {
      method: "POST",
      headers: {
        "api-key": COGNI_PLATFORM_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();

    return resData;
  } catch (error) {
    console.log("Error sending user to Cognipharma platform: ", error);
  }
}

export async function users_SendToCognipharmaPlatform(data) {
  const cogniPlatformEndpoint = process.env.COGNI_PLATFORM_URL + "/" + COGNI_PLATFORM_APPNAME + "/idc";
  try {
    //const response = await api.post(cogniPlatformEndpoint, data)
    const response = await fetch(cogniPlatformEndpoint, {
      method: "POST",
      headers: {
        "api-key": process.env.COGNI_PLATFORM_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    const resData = await response.json();
    return resData;
  } catch (error) {
    console.log("Error sending users to Cognipharma platform: ", error);
  }
}
