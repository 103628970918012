import ReactGA from "react-ga4";
import { isLocalhost } from "util";

const TrackingCode = "G-R7RVT5Y2W3";

export const initializeAnalytics = () => {
  // Initialize tracking
  ReactGA.initialize(TrackingCode);
  if (isLocalhost) {
    // disable GA:
    window["ga-disable-" + TrackingCode] = true;
  }
};
export const disableAnalytics = () => {
  window["ga-disable-" + TrackingCode] = true;
};
