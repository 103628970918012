import DDIIcon from "./ddi_icon";

export const DDILegendWithText = () => {
  return (
    <>
      <hr className="m-4" />
      <p>
        Os medicamentos de determinado grupo farmacol&oacute;gico s&atilde;o classificados em 4 categorias de cores, que
        se aproximam às cores de um sem&aacute;foro, dependendo do risco de intera&ccedil;&otilde;es medicamentosas
        (DDIs) com os inibidores de CDK4/6 (CDK 4/6i):
      </p>
      <p className="ps-3">
        <DDIIcon value={0} />{" "}
        <span className="ms-2">
          <strong>Interação não esperada</strong>
        </span>
        <br />
        <DDIIcon value={1} />{" "}
        <span className="ms-2">
          <strong>Interação de baixo potencial</strong>
        </span>
        <br />
        <DDIIcon value={2} />{" "}
        <span className="ms-2">
          <strong>Interação potencial</strong>
        </span>
        <br />
        <DDIIcon value={3} />{" "}
        <span className="ms-2">
          <strong>Não coadministrar</strong>
        </span>
        <br />
      </p>
    </>
  );
};
