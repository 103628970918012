import md5 from "js-md5";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSessionId } from "util";

const AuthCallback = () => {
  const [searchParams] = useSearchParams();
  const [sessionIsValid, setSessionIsValid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let session_id = searchParams.get("session_id");
    let verification_code = searchParams.get("verification_code");
    let unique_key = searchParams.get("unique_key");

    let validation = md5(session_id + "06aa023b-79fb-456d-8170-0815edbf352f");
    if (session_id === getSessionId() && validation.toLowerCase() === verification_code.toLowerCase()) {
      localStorage.setItem("cogni-user-id", unique_key);
      localStorage.setItem("login-token", verification_code);

      let redirectTo = sessionStorage.getItem("redirectTo") ?? "/";
      // sessionStorage.removeItem("redirectTo");
      setSessionIsValid(true);
      navigate(redirectTo);
      console.log("Valid session / authentication", redirectTo);
    } else {
      console.log("Invalid session");
      setSessionIsValid(false);
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    }
  }, [searchParams, navigate]);

  return (
    <div className="login">
      {!sessionIsValid && (
        <div
          className="text-white h4 d-flex align-items-center justify-content-center flex-column font-size-lg text-center"
          style={{ minHeight: "450px" }}
        >
          {sessionIsValid == null && "A validar a sua sessão."}
          {sessionIsValid === false && (
            <>
              <p>
                Não foi possível validar a sua sessão com sucesso. <br />
                Por favor valide que introduziu as credenciais corretamente.
              </p>
              <p className="mt-3 h6">A redirecionar para o login/registo...</p>
            </>
          )}
        </div>
      )}
      {sessionIsValid === true && "Sessão validada com sucesso. A redireccionar..."}
    </div>
  );
};

export default AuthCallback;
