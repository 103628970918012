import { useState } from "react";
import { DDIs, filterDDIs } from "data/ddis";
import DdiGroup from "components/ddi_group";
import { Col, Row } from "react-bootstrap";
import ga4 from "react-ga4";


const Search = () => {
  const [curList, setCurList] = useState(DDIs);
  const [filter, setFilter] = useState("");
  const [eventTimeout, setEventTimeout] = useState(0);

  const searchChange = e => {
    if (eventTimeout) clearTimeout(eventTimeout);
    setEventTimeout(
      setTimeout(() => {
        if (e.target.value?.trim() !== "") {
          ga4.event({
            category: "app-interaction",
            action: "drug-search",
            label: e.target.value,
            search_term: e.target.value,
          });
        }
      }, 3000)
    );
    setFilter(e.target.value);
    setCurList(filterDDIs(e.target.value));
  };

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h2 className="m-0 ms-lg-4 mb-0 text-center text-sm-start">Interações Medicamentosas</h2>
        </Col>
        <Col className="d-flex justify-content-center justify-content-lg-end">
          <div className=" m-0 me-lg-4 position-relative input-wrapper" style={{ width: "300px" }}>
            {/* <small className="text-muted">Pesquise por nome de fármaco:</small>
        <br /> */}
            <input
              type="text"
              placeholder="Pesquisa - Nome do fármaco..."
              value={filter}
              onChange={searchChange}
              style={{ color: "#222", width: "100%" }}
            />
            <span className="input-group-append">
              <i className="bi-search"></i>
            </span>
          </div>
        </Col>
      </Row>

      {curList?.map((cat, idx) => (
        <div key={idx}>
          <DdiGroup group={cat} patientControls="add" />
        </div>
      ))}
      {(!curList || curList.length === 0) && (
        <div className="bg-light m-5 p-3 font-weight-bold rounded text-center">
          Nenhum fármaco encontrado para a sua pesquisa.
        </div>
      )}
    </>
  );
};

export default Search;
