import { Col, Row } from "react-bootstrap";

export const SupportBox = () => {
  return (
    <div className="alert alert-light container" style={{ maxWidth: "800px" }} role="alert">
      <Row>
        <Col xs="12" sm="2" md="1" className="text-center">
          <i className="bi bi-question-circle fs-1" style={{ opacity: "0.2" }} />
        </Col>
        <Col>
          <p>
            Para obter ajuda com o registo, ou em caso de qualquer problema técnico com a aplicação, por favor entre em
            contacto com a nossa equipa de suporte através dos seguintes contactos:
          </p>
          <ul className="list-unstyled">
            <li className="mb-2">
              <i className="bi bi-envelope me-2" />
              <a
                className="text-decoration-none fw-bold text-secondary"
                href="mailto:support+sps@cognipharma.com?subject=%5BiCyclin%5D%20Pedido%20de%20suporte&body=Gostaria%20de%20obter%20suporte%20t%C3%A9cnico%20para%20utiliza%C3%A7%C3%A3o%20da%20aplica%C3%A7%C3%A3o%20iCyclin.%0D%0A%0D%0AA%20minha%20quest%C3%A3o%2Fproblema%20%C3%A9%3A%0D%0A%0D%0AOs%20meus%20contactos%3A%0D%0A-%20Nome%3A%0D%0A-%20Email%3A%0D%0A-%20Telefone%3A%0D%0A-%20Hora%20preferencial%20de%20contacto%3A%0D%0A%0D%0AObrigado%2Fa"
                target="_blank"
                rel="noreferrer"
              >
                support+sps@cognipharma.com
              </a>
            </li>
            <li className="mb-2">
              <i className="bi bi-telephone-fill me-2" />
              <a className="text-decoration-none fw-bold text-secondary" href="tel:+351934989433">
                934 989 433
              </a>
            </li>
            <li className="mb-2">
              <i className="bi bi-globe me-2" />
              <a
                className="text-decoration-none fw-bold text-secondary"
                href="https://www.cognipharma.com/hcp-engagement-platform/help-pt/"
                target="_blank"
                rel="noreferrer noopener"
              >
                cognipharma.com
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};
