import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { Navigate, useLocation } from "react-router-dom";
import { RouteOptions } from "App";
import PatientView from "components/patient-view";
import { isInStandaloneMode } from "util";
import { isIos } from "util";

function Layout({ children, hideHeaderContent }) {
  const { pathname } = useLocation();

  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [showPatientView, setShowPatientView] = useState(false);

  useEffect(() => {
    document.title = (RouteOptions[pathname].title + " - " ?? "Página desconhecida - ") + "iCyclin";

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }

    // Hide patient view in routes that shouldn't display it
    setShowPatientView(RouteOptions[pathname].showPatientView);

  }, [pathname]);

  const isPrivate = pathname.indexOf("/private") >= 0;
  let userToken = localStorage.getItem("login-token");
  if (isPrivate && !userToken) {
    sessionStorage.setItem("redirectTo", pathname);
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="App">
      <Header showMenuItems={!hideHeaderContent} />
      <main>
        <div className="layout container">{children}</div>

        {showPatientView && <PatientView />}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
