import React, { useEffect, useState } from "react";
import DdiList from "./ddi_list";
import { DDI_GetGroup } from "data/ddis";

const DdiGroup = ({ group, showHeader = true, patientControls = null }) => {
  const [localGroup, setLocalGroup] = useState(group);

  useEffect(() => {
    if (typeof group === "string") {
      setLocalGroup(DDI_GetGroup(group));
    } else if (group?.category) {
      setLocalGroup(group);
    } else {
      setLocalGroup(null);
    }
  }, [group]);

  return (
    <div className="ddi-group">
      {showHeader && <h3>{localGroup?.category}</h3>}
      <div>
        <DdiList list={localGroup?.medications} patientControls={patientControls} />
      </div>
    </div>
  );
};

export default DdiGroup;
