export const db_DDIs = [
  {
    "category": "Analgésicos",
    "medications": [
      {
        "name": "Ácido acetilsalicílico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Alfentanilo",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Buprenorfina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Celecoxib",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Codeína",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Dextropropoxifeno",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Diacetilmorfina (Diamorfina)",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Diclofenac",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Di-hidrocodeína",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fentanilo",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidrocodona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidromorfona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ibuprofeno",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ácido mefenâmico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metamizol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Metadona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Morfina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Naproxeno",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Nimesulida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Oxicodona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Paracetamol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Petidina (Meperidina)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Piroxicam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Tramadol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Antiarrítmicos",
    "medications": [
      {
        "name": "Amiodarona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Bepridilo",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Disopiramida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Flecainida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Lidocaína",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Mexiletina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Propafenona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Quinidina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      }
    ]
  },
  {
    "category": "Antibacterianos",
    "medications": [
      {
        "name": "Amicacina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Amoxicilina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ampicilina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Azitromicina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Bedaquilina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Capreomicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cefalexina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Cefazolina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cefixima",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cefotaxima",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ceftazidima",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ceftriaxona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cloranfenicol",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Ciprofloxacina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Claritromicina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Ácido clavulânico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clindamicina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Clofazimina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Cloxacilina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cicloserina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dapsona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Doxiciclina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ertapenem",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Eritromicina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Etambutol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Etionamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Flucloxacilina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Gentamicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Imipenem / Cilastatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Isoniazida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Canamicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Levofloxacina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Linezolida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Meropenem",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metronidazol",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Moxifloxacina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Nitrofurantoína",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ofloxacina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Ácido para-aminossalicílico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Penicilinas",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Pirazinamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Rifabutina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Rifampicina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Rifapentina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Rifaximina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Espectinomicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Estreptomicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Sulfadiazina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Tazobactam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Telitromicina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Tetraciclina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Trimetoprim / Sulfametoxazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Vancomicina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Anticoagulantes, Antiagregantes Plaquetários e Fibrinolíticos",
    "medications": [
      {
        "name": "Acenocumarol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Apixabano",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Clopidogrel",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dabigatrano",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Dalteparina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dipiridamol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Edoxabano",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Eltrombopag",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Enoxaparina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fondaparinux",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Heparina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Nadroparina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Femprocumona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Prasugrel",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Rivaroxabano",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Estreptoquinase",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ticagrelor",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Tinzaparina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Varfarina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 2,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Anticonvulsivantes",
    "medications": [
      {
        "name": "Carbamazepina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Fosfenitoína",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Gabapentina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Oxcarbazepina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Fenobarbital (Fenobarbitona)",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Fenitoína",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Pregabalina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ácido valpróico (Valproato)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Antidepressivos",
    "medications": [
      {
        "name": "Agomelatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Amitriptilina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Bupropiom",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Citalopram",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Clomipramina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Desipramina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Doxepina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Duloxetina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Escitalopram",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Fluoxetina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fluvoxamina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 0
        }
      },
      {
        "name": "Imipramina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Lítio",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Maprotilina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Mianserina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Milnaciprano",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Mirtazapina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nefazodona",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Nortriptilina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Paroxetina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fenelzina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Reboxetina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Sertralina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Tranilcipromina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Trazodona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Trimipramina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Venlafaxina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Antidiabéticos",
    "medications": [
      {
        "name": "Acarbose",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dulaglutido",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Exenatido",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Glibenclamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Gliclazida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Glimepirida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Glipizida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Insulina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Linagliptina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Liraglutido",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metformina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Nateglinida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Pioglitazona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Repaglinida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Rosiglitazona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Saxagliptina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Sitagliptina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Tolbutamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Vildagliptina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      }
    ]
  },
  {
    "category": "Antifúngicos",
    "medications": [
      {
        "name": "Anfotericina B",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Anidulafungina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Caspofungina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fluconazol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Flucitosina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Griseofulvina",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Itraconazol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Cetoconazol",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Miconazol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Nistatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Posaconazol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Terbinafina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Voriconazol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      }
    ]
  },
  {
    "category": "Anti-histamínicos",
    "medications": [
      {
        "name": "Astemizol",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Cetirizina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clorofenamina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clemastina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Difenidramina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Fexofenadina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Levocetirizina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Loratadina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Prometazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      }
    ]
  },
  {
    "category": "Antipsicóticos / Neurolépticos",
    "medications": [
      {
        "name": "Amissulprida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Aripiprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Asenapina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cloropromazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Clozapina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Flufenazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Haloperidol",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Iloperidona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Levomepromazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Olanzapina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Paliperidona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Perazina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Periciazina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Perfenazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Pimozida",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Pipotiazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Quetiapina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Risperidona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Sertindol",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Sulpirida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Tioridazina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Tiaprida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Ziprasidona",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Zotepina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Zuclopentixol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Ansiolíticos / Hipnóticos / Sedativos",
    "medications": [
      {
        "name": "Alprazolam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Bromazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Buspirona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Clorodiazepóxido",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Clorazepato",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Diazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Estazolam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Flunitrazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Flurazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidroxizina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Lorazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Lormetazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Midazolam (oral)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Midazolam (parentérico)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Oxazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Temazepam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Triazolam",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Zaleplom",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Zolpidem",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Zopiclona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Bloqueadores Beta",
    "medications": [
      {
        "name": "Atenolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Bisoprolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 0
        }
      },
      {
        "name": "Carvedilol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metoprolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Nebivolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Oxprenolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Pindolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Propranolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Sotalol",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Timolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Bifosfonatos",
    "medications": [
      {
        "name": "Ácido alendrónico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ácido ibandrónico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ácido pamidrónico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ácido zoledrónico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Broncodilatadores",
    "medications": [
      {
        "name": "Formoterol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Brometo de ipratrópio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Montelucaste",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Salbutamol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Salmeterol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Teofilina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Bloqueadores dos Canais de Cálcio",
    "medications": [
      {
        "name": "Amlodipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Diltiazem",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Felodipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nicardipina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nifedipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nisoldipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nitrendipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Verapamilo",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Contracetivos / Terapêutica Hormonal de Substituição",
    "medications": [
      {
        "name": "Desogestrel",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Drospirenona",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Didrogesterona",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Estradiol",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Etinilestradiol",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Etonogestrel",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Gestodeno",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Levonorgestrel",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Levonorgestrel  (Contraceção de emergência)",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 2,
          "ribociclib": 3
        }
      },
      {
        "name": "Medroxiprogesterona (injetável de libertação prolongada)",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Medroxiprogesterona  (outras formulações)",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Norelgestromina",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Noretisterona (Noretindrona)",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Norgestimato",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Norgestrel",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Ulipristal",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      }
    ]
  },
  {
    "category": "Agentes Gastrointestinais",
    "medications": [
      {
        "name": "Alosetron",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidróxido de alumínio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Antiácidos",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Bisacodilo",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cimetidina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Cisaprida",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Esomeprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Famotidina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Lactulose",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Lansoprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Loperamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Mesalazina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Omeprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Pantoprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Prucaloprida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Rabeprazol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ranitidina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Sene",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Agentes Gastrointestinais (Antieméticos)",
    "medications": [
      {
        "name": "Aprepitant",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Dolasetron",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Domperidona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Dronabinol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Fosaprepitant",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Granissetrom",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Metoclopramida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ondansetrom",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Palonossetrom",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Proclorperazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Tropissetrom",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      }
    ]
  },
  {
    "category": "Plantas Medicinais / Suplementos / Vitaminas",
    "medications": [
      {
        "name": "Sumo de toranja",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Chá verde",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Suplementos de ferro",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Nicotinamida (Niacinamida)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fitomenadiona (Vitamina K)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Piridoxina (Vitamina B6)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Retinol (Vitamina A)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Riboflavina (Vitamina B2)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Erva de São João (Hipericão)",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Tiamina (Vitamina B1)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Vitamina E",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Agentes para a Hipertensão / Insuficiência Cardíaca",
    "medications": [
      {
        "name": "Aliscireno",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Ambrisentano",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Amilorida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Bendroflumetiazida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Bosentano",
        "interactions": {
          "abemaciclib": 2,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Candesartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Captopril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clorotalidona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cilazapril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clonidina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Digoxina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Dopamina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Doxazosina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Enalapril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Eprosartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Furosemida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Hidralazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidroclorotiazida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Indapamida",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 0,
          "ribociclib": 3
        }
      },
      {
        "name": "Irbesartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dinitrato de isossorbida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Ivabradina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Labetalol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Lacidipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Lercanidipina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Lisinopril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Losartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Macitentano",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Metildopa",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metolazona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Olmesartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Perindopril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Prazosina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Quinapril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ramipril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ranolazina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Sildenafil (Hipertensão  arterial pulmonar)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nitroprussiato de sódio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Espironolactona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Tadalafil (Hipertensão  arterial pulmonar)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Telmisartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Torasemida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Trandolapril",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Valsartan",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Xipamida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Imunomoduladores",
    "medications": [
      {
        "name": "Hidroxiureia (Hidroxicarbamida)",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Interferão alfa",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Interleucina-2 (Aldesleucina)",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Peginterferão alfa-2a",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      }
    ]
  },
  {
    "category": "Imunossupressores",
    "medications": [
      {
        "name": "Azatioprina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Ciclosporina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Everolímus (Imunossupressor)",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Micofenolato",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Sirolímus",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Tacrolímus",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Antidislipidémicos",
    "medications": [
      {
        "name": "Atorvastatina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Bezafibrato",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Clofibrato",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Ezetimiba",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fenofibrato",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Óleos de peixe",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Fluvastatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Gemfibrozil",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Lovastatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Pitavastatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Pravastatina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 1
        }
      },
      {
        "name": "Rosuvastatina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 1
        }
      },
      {
        "name": "Sinvastatina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  },
  {
    "category": "Outros",
    "medications": [
      {
        "name": "Alfuzosina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Alopurinol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Cálcio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Colquicina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 2,
          "ribociclib": 2
        }
      },
      {
        "name": "Dutasterida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Finasterida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Levotiroxina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Vacinas vivas",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Magnésio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metilfenidato",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Potássio",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Tansulosina",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Tolterodina",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 3
        }
      },
      {
        "name": "Ácido tranexâmico",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      }
    ]
  },
  {
    "category": "Esteroides",
    "medications": [
      {
        "name": "Beclometasona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Betametasona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Budesonida",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Clobetasol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Dexametasona",
        "interactions": {
          "abemaciclib": 1,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Fludrocortisona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Fluticasona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidrocortisona (oral)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Hidrocortisona (tópica)",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Acetato de megestrol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Metilprednisolona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Mometasona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Nandrolona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Prednisolona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Prednisona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      },
      {
        "name": "Estanozolol",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 0,
          "ribociclib": 0
        }
      },
      {
        "name": "Testosterona",
        "interactions": {
          "abemaciclib": 3,
          "palbociclib": 3,
          "ribociclib": 3
        }
      },
      {
        "name": "Triamcinolona",
        "interactions": {
          "abemaciclib": 0,
          "palbociclib": 1,
          "ribociclib": 2
        }
      }
    ]
  }
];