import { usePatientContext } from "data/patientContext";
import { Button } from "react-bootstrap";
import DdiList from "./ddi_list";
import { Link } from "react-router-dom";

const PatientView = () => {
  const patient = usePatientContext();

  const clear = () => {
    if (!window.confirm("Tem a certeza que deseja limpar este perfil de doente?")) return;
    patient.clear();
  };

  return (
    <>
      {patient.drugList?.length > 0 && (
        <div className={"patient-view scroll-shadows-parent"}>
          <div className="container d-flex flex-row justify-content-between align-items-center">
            <h3>
              Perfil de doente
              <Link to="/private/patient" className="ms-2 small btn btn-sm btn-outline-primary small">
                Ver Perfil
                <i className="bi bi-arrow-right ms-2 text-decoration-none color-primary" />
              </Link>
            </h3>
            <Button className="btn btn-icon" title="Limpar e Fechar Doente" onClick={clear}>
              <i className="bi bi-x fs-3" />
            </Button>
          </div>
          <DdiList list={patient.drugList} patientControls="remove,group" />
        </div>
      )}
    </>
  );
};
export default PatientView;
