import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center d-flex align-items-center justify-content-center flex-column flex-md-row">
            <div>
              <span className="d-block d-md-inline">
                2024 ©
                <a
                  href="https://www.spsenologia.pt?utm_source=app-ciclib&utm_medium=web&utm_content=app-footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ms-1"
                >
                  Sociedade Portuguesa de Senologia
                </a>
              </span>
              <span className="d-none d-md-inline me-1 ms-1"> | </span>
            </div>
            <div className="d-flex d-md-inline text-center align-items-center justify-content-center">
              <span className="">Todos os direitos reservados | </span>
              <a href="#" className="cky-banner-element btn btn-link small text-white">Cookies</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
