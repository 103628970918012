import React from "react";
import DdiItem from "./ddi_item";
import { Container } from "react-bootstrap";

const DdiList = ({ list, showHeader = true, patientControls }) => {
  return (
    <Container fluid className="ddi-list-container scroll-shadows justify-content-center">
      <table className="ddi-tbl mt-3 mb-5">
        {showHeader && (
          <thead className="ddi-list sticky-top">
            <tr>
              <th></th>
              <th>
                Abema<span style={{ fontSize: "0.9em", fontWeight: "400" }}>ciclib</span>
              </th>
              <th>
                Palbo<span style={{ fontSize: "0.9em", fontWeight: "400" }}>ciclib</span>
              </th>
              <th>
                Ribo<span style={{ fontSize: "0.9em", fontWeight: "400" }}>ciclib</span>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {list?.map((element, idx) => {
            return <DdiItem data={element} key={idx} patientControls={patientControls} />;
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default DdiList;
