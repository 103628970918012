import React, { useEffect, useRef, useState } from "react";
import { DDI_Groups } from "data/ddis";
import { Col, Container, Row } from "react-bootstrap";
import DdiGroup from "./ddi_group";
import ga4 from "react-ga4";

const Groups = ({ select }) => {
  const groups = DDI_Groups();

  const ref = useRef(null);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const unsetGroup = () => {
    setSelectedGroup(null);
  };
  const onSelectGroup = e => {
    const category = e.target?.getAttribute("data-group") ?? null;
    selectGroup(category);
    ga4.event({
      category: "app-interaction",
      action: "group-open",
      label: category,
      group_category: category,
    });
  };
  const selectGroup = category => {
    setSelectedGroup(category);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  useEffect(() => {
    if (select?.length > 0) {
      selectGroup(select);
    }
  }, [select]);

  return (
    <Container fluid>
      <Row className="g-3">
        {groups.map((group, idx) => {
          if (selectedGroup && selectedGroup === group?.category) {
            return (
              <Col xs={12} key={idx}>
                <div
                  className="card-pink-selected d-flex align-items-center justify-content-center flex-column"
                  data-group={group?.category}
                >
                  <div className="card-header" ref={ref} onClick={unsetGroup}>
                    {group?.category}
                  </div>
                  <div className="card-detail d-flex">
                    <DdiGroup group={selectedGroup} showHeader={false} patientControls="add" />
                  </div>
                </div>
              </Col>
            );
          } else {
            return (
              <Col sm={6} md={4} lg={3} key={idx}>
                <div
                  className="card-pink d-flex align-items-center justify-content-center"
                  data-group={group?.category}
                  onClick={onSelectGroup}
                >
                  {group?.category}
                </div>
              </Col>
            );
          }
        })}
      </Row>
    </Container>
  );
};

export default Groups;
