import { db_DDIs } from "./db";

// function compareByName(typedStr, comparedStr) {
//   var normalizedStr = typedStr.normalize("NFD").replace(/\p{Diacritic}/gu, "");
//   return normalizedStr == comparedStr;
// }

export const filterDDIs = search => {
  if (!search || search.trim().length === 0) return DDIs;
  search = search
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  const filtered = DDIs.map(cat => {
    const meds = cat?.medications?.filter(
      drug =>
        drug.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .search(search) >= 0
    );
    return {
      category: cat.category,
      medications: meds ?? [],
    };
  });
  return filtered.filter(cat => cat.medications.length > 0);
};

export const DDI_Groups = () => {
  return DDIs.map(cat => {
    const group = { ...cat };
    delete group.medications;
    return group;
  });
};
export const DDI_GetGroup = category => {
  return DDIs.find(g => {
    return g.category === category;
  });
};

export const getDrugGroup = drugName => {
  if (!drugName || drugName.length === 0) return DDIs;

  const filtered = DDIs.map(cat => {
    const meds = cat?.medications?.filter(drug => drug.name === drugName);
    return {
      category: cat.category,
      medications: meds ?? [],
    };
  });
  const groups = filtered.filter(cat => cat.medications.length > 0);
  if (groups.length === 1) {
    return groups[0].category;
  }
  return null;
};

export const DDIs = db_DDIs;
