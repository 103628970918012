import { createContext, useState, useContext } from "react";
import ga4 from "react-ga4";

const PatientContext = createContext();
export default PatientContext;

export const PatientContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [drugList, setDrugList] = useState(null);

  const clear = () => {
    setDrugList(null);
    ga4.event({
      category: "app-interaction",
      action: "patient-clear"
    });
  };

  const add = item => {
    // Do not add duplicates
    if (drugList?.find(element => element.name === item.name)) return;
    // Add to the list
    setDrugList(prevList => (prevList ? [...prevList, item] : [item]));

    ga4.event({
      category: "app-interaction",
      action: "patient-add-drug",
      label: item.name,
      drug_name: item.name,
    });
  };
  const remove = item => {
    setDrugList(prevList => prevList.filter(element => element.name !== item.name));

    ga4.event({
      category: "app-interaction",
      action: "patient-remove-drug",
      label: item.name,
      drug_name: item.name,
    });
  };

  return (
    // the Provider gives access to the context to its children
    <PatientContext.Provider value={{ drugList, add, remove, clear }}>{children}</PatientContext.Provider>
  );
};

// context consumer hook
export const usePatientContext = () => {
  // get the context
  const context = useContext(PatientContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("usePatientContext was used outside of its Provider");
  }

  return context;
};
