import React from "react";


const Sponsors = () => {
  return (
    <div>
      <div className="d-flex justify-content-center mt-5 small text-muted">Aplicação desenvolvida com o apoio de</div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        <div className="me-1 me-md-3 ">
          <img src={require("img/logo-lilly.png")} alt="logo Lilly" style={{ height: "40px" }} />
        </div>
        {/* <div className="ms-md-3 pb-1"> */}
        <div className="mx-md-3 pb-1">
          <img src={require("img/logo-novartis.png")} alt="logo Novartis" style={{ height: "60px" }} />
        </div>
        <div className="ms-1 ms-md-3">
          <img src={require("img/logo-pfizer.png")} alt="logo Pfizer" style={{ height: "60px" }} />
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
