import React, { useState } from "react";
import {  Col, Nav, Row } from "react-bootstrap";

const HelpInstall = () => {
  const [selectedTab, setSelectedTab] = useState("ios");

  return (
    <>
      <div className="text-center mt-5">
        <h2>Instalar a app</h2>
      </div>
      <div className="mx-lg-5 text-justified">
        <p>
          De forma a ter um acesso imediato à aplicação, poderá adicionar a mesma ao ecrã de entrada no seu
          telemóvel/tablet, tal como uma aplicação descarregada das AppStores.
          <br />
          Siga as instruções abaixo, consoante o seu dispositivo.
        </p>

        <div>
          <Nav variant="tabs" defaultActiveKey={selectedTab} onSelect={setSelectedTab}>
            <Nav.Item>
              <Nav.Link eventKey="android">Android / Chrome</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="ios">iPhone / iPad</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="tabs-container">
            <Row>
              <Col xs="12" md="6" className="pt-4">
                {selectedTab === "android" && (
                  <div>
                    <ol>
                      <li>
                        No seu dispositivo Android, abra o Chrome{" "}
                        <img
                          src={require("img/icons/chrome.png")}
                          width="18"
                          height="18"
                          alt="Chrome"
                          className="mx-2"
                        />
                        .
                      </li>
                      <li>
                        Toque em <strong>Instalar</strong>.
                      </li>
                      <li>
                        Se a opção para instalar não lhe aparecer, toque em{" "}
                        <i className="bi bi-three-dots-vertical"></i> para aceder ao menu, e depois em{" "}
                        <strong>Instalar aplicação</strong>
                      </li>
                      <li>Siga as instruções no ecrã.</li>
                      <li>
                        Abra a app iCyclin através do seu ícone:
                        <br />
                        <img
                          className="text-center mt-2 ms-5"
                          src="/logo192.png"
                          width="60"
                          height="auto"
                          alt="iCyclin app logo"
                        />
                      </li>
                    </ol>
                  </div>
                )}
                {selectedTab === "ios" && (
                  <div>
                    <ol>
                      <li>
                        No seu iPhone/iPad, abra o Safari
                        <img
                          src={require("img/icons/safari.png")}
                          width="18"
                          height="18"
                          alt="Safari"
                          className="mx-2"
                        />
                        .
                      </li>
                      <li>
                        Toque em <strong>Partilhar</strong>
                        <img
                          src={require("img/icons/share-icon-iphone-20.png")}
                          height="18"
                          alt="Partilhar"
                          className="mx-2"
                        />
                        .
                      </li>
                      <li>
                        Faça scroll na janela de partilha, procure e toque na opção{" "}
                        <strong>Adicionar ao ecrã principal</strong>{" "}
                        <i className="bi bi-plus-square-fill text-muted"></i>
                      </li>
                      <li>
                        Confirme ou edite os detalhes do Website e toque em <strong>Adicionar</strong>.
                      </li>

                      <li>
                        Abra a app iCyclin através do seu ícone:
                        <br />
                        <img
                          className="text-center mt-2 ms-5"
                          src="/logo192.png"
                          width="60"
                          height="auto"
                          alt="iCyclin app logo"
                        />
                      </li>
                    </ol>
                  </div>
                )}
              </Col>
              <Col xs="12" md="6" className="d-flex justify-content-center">
                <div style={{ width: "100%", maxWidth: "300px" }}>
                  <div
                    style={{
                      padding: (selectedTab === "android" ? "222.22" : "216.22") + "% 0 0 0",
                      position: "relative",
                    }}
                  >
                    <iframe
                      src={
                        "https://player.vimeo.com/video/" +
                        (selectedTab === "android" ? "921121388" : "921168112") +
                        "?badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0&background=1&muted=1"
                      }
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 20px rgba(0,0,0,0.3)",
                      }}
                      title="iCyclin - Instalar a app"
                    ></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpInstall;
