import { HCP_TYPE } from "util";
import { CognipharmaHCPLookup } from "./cognipharma-platform";
import { disableAnalytics } from "./analytics";

export const authenticateHCP = async (hcpType, name, licenseNumber) => {
  localStorage.removeItem("cogni-user-id");
  localStorage.removeItem("login-token");

  try {
    if (name?.toLowerCase() === "cognipharma" || name?.toLowerCase() === "sps") {
      if (licenseNumber === "23224") {
        localStorage.setItem("cogni-user-id", name.toLowerCase());
        localStorage.setItem("login-token", hcpType + "|" + licenseNumber + "|" + name);

        if (name?.toLowerCase() === "cognipharma") {
          disableAnalytics();
        }

        return { isValid: true, datasource: "Administradores", validationMessage: "Acesso garantido" };
      } else {
        return { isValid: false, datasource: "Administradores", validationMessage: "Password Inválida" };
      }
    }

    // localStorage.setItem("cogni-user-id", name.toLowerCase());
    // localStorage.setItem("login-token", hcpType + "|" + licenseNumber + "|" + name);
    // return { isValid: true, datasource: "TESTE", validationMessage: "TESTE" };

    const results = await CognipharmaHCPLookup(hcpType, name, licenseNumber);

    console.log("HCP Authentication results", results);

    if (results?.isValid) {
      localStorage.setItem("cogni-user-id", name.toLowerCase());
      localStorage.setItem("login-token", hcpType + "|" + licenseNumber + "|" + name);
      return { isValid: true, datasource: results.datasource, validationMessage: results.validationMessage };
    } else {
      return {
        isValid: false,
        datasource: results?.datasource ?? "Sem datasource",
        validationMessage: results?.validationMessage ?? results?.error?.message,
      };
    }
  } catch (er) {
    console.log("Erro na autenticação", er);
    return {
      isValid: false,
      datasource: "-",
      validationMessage: "Erro Interno. Por favor contacte o suporte em support+sps@cognipharma.com",
    };
  }
};

export const logoutHCP = () => {
  localStorage.removeItem("cogni-user-id");
  localStorage.removeItem("login-token");
};
export const hcpIsLoggedIn = () => {
  return localStorage.getItem("login-token")?.length > 0;
};
export const hcpGetAuthInfo = () => {
  let data = localStorage.getItem("login-token");
  if (!data) return null;
  data = data.split("|");
  if (data.length !== 3) return null;
  return {
    hcpType: data[0],
    job: JobFromHCPType(data[0]),
    name: data[2],
    licenseNumber: data[1],
  };
};

function JobFromHCPType(hcpType) {
  switch (hcpType) {
    case HCP_TYPE.Doctor:
      return "Médico/a";
    case HCP_TYPE.Pharmacist:
      return "Farmacêutico/a";
    case HCP_TYPE.Nurse:
      return "Enfermeiro/a";
    default:
      return "Outra";
  }
}
