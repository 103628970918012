import React from "react";
import DDIIcon from "./ddi_icon";
import { Button } from "react-bootstrap";
import { usePatientContext } from "data/patientContext";
import { useNavigate } from "react-router-dom";
import { getDrugGroup } from "data/ddis";
import ga4 from "react-ga4";

const DdiItem = ({ data, patientControls }) => {
  const patient = usePatientContext();
  const navigate = useNavigate();

  const addToPatient = () => {
    patient.add(data);
  };
  const removeFromPatient = () => {
    patient.remove(data);
  };
  const switchDrug = () => {
    const groupName = getDrugGroup(data.name);
    console.log("Viewing alternatives ", groupName);
    ga4.event({
      category: "app-interaction",
      action: "patient-switch-drug-category",
      label: groupName,
      drug_name: groupName,
    });
    ga4.event({
      category: "app-interaction",
      action: "patient-switch-drug",
      label: data.name,
      drug_name: data.name,
    });

    navigate("/private/groups", { state: { category: groupName } });
  };

  return (
    <tr className="ddi-row onhover-container">
      <td>
        {patientControls?.indexOf("add") >= 0 && (
          <Button
            onClick={addToPatient}
            className="btn-icon me-2 onhover"
            size="sm"
            title="Adicionar ao perfil de doente"
          >
            <i className="bi bi-person-fill-add" />
          </Button>
        )}
        {patientControls?.indexOf("remove") >= 0 && (
          <Button
            onClick={removeFromPatient}
            className="btn-icon me-2 onhover"
            size="sm"
            title={"Remover do perfil de doente"}
          >
            <i className="bi bi-x" />
          </Button>
        )}
        {patientControls?.indexOf("group") >= 0 && (
          <Button
            onClick={switchDrug}
            className="btn-icon me-2 onhover"
            size="sm"
            title={"Encontrar alternativa do mesmo grupo farmacêutico"}
          >
            <i className="bi bi-arrow-left-right" />
          </Button>
        )}
        {data.name}
      </td>
      <td>
        <DDIIcon value={data.interactions.abemaciclib} />
      </td>
      <td>
        <DDIIcon value={data.interactions.palbociclib} />
      </td>
      <td>
        <DDIIcon value={data.interactions.ribociclib} />
      </td>
    </tr>
  );
};

export default DdiItem;
