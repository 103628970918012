import InternalLogin from "components/base/internal_login";
import React, { useEffect, useRef, useState } from "react";
import { getSessionId } from "util";

const Login = () => {
  const ref = useRef(null);
  const useHCPPlatform = false;

  const [isOnline, setIsOnline] = useState(null);
  const [sessionId, setSessionId] = useState(getSessionId());

  const onMessageReceived = e => {
    const COGNIPHARMA_CLIENT_MESSAGE_PREFIX = "cognipharma-id=";
    try {
      if (e.data?.indexOf && e.data.indexOf(COGNIPHARMA_CLIENT_MESSAGE_PREFIX) >= 0) {
        const msgString = e.data.substring(COGNIPHARMA_CLIENT_MESSAGE_PREFIX.length);
        const msgObject = JSON.parse(msgString);

        const newHeight = msgObject.Height;
        if (Object.keys(msgObject).indexOf("RedirectTop") >= 0) {
          window.location.href = msgObject.RedirectTop;
        } else if (msgObject.Screen?.toLowerCase() === "register") {
          if (!ref.current) return;
          ref.current.style.minHeight = (newHeight ?? "1100") + "px";
          document.querySelector(".layout.container").scroll(0, 0);
        } else if (newHeight > 0) {
          ref.current.style.minHeight = newHeight + "px";
          document.querySelector(".layout.container").scroll(0, 0);
        }
      }
    } catch (err) {
      console.log("Error parsing iframe message.", err, e);
    }
  };

  const onNetworkStatusChange = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("message", onMessageReceived);
    window.addEventListener("online", onNetworkStatusChange);
    window.addEventListener("offline", onNetworkStatusChange);

    setSessionId(getSessionId());

    setTimeout(onNetworkStatusChange, 100);
    return () => {
      window.removeEventListener("message", onMessageReceived);
      window.removeEventListener("online", onNetworkStatusChange);
      window.removeEventListener("offline", onNetworkStatusChange);
    };
  }, []);

  return (
    <div className="login">
      {!isOnline && (
        <div
          className="text-white h4 d-flex align-items-center justify-content-center flex-column font-size-lg text-center"
          style={{ minHeight: "450px" }}
        >
          {isOnline == null && "A detetar rede."}
          {isOnline === false && (
            <>
              <p>Esta aplicação é reservada a profissionais de saúde.</p>
              <p>Para se registar na aplicação precisa de ter uma ligação à internet.</p>
            </>
          )}
        </div>
      )}
      {isOnline === true && useHCPPlatform && (
        <iframe
          title="Login Form"
          ref={ref}
          id="login_form"
          src={
            "https://cloud.cognipharma.com/id/rest/v1/iCyclin/embed/authorize?background=transparent&session_id=" +
            sessionId
          }
          sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
          width="100%"
          height="500"
        ></iframe>
      )}
      {isOnline === true && !useHCPPlatform && <InternalLogin />}
    </div>
  );
};

export default Login;
