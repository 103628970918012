import React from "react";

const References = () => (
  <>
    <div className="text-center mt-5">
      <h2>Referências</h2>
    </div>
    <div className="mx-lg-5 text-justified">
      <p>
        Este material foi desenvolvido com base nos dados publicados pela Universidade de Liverpool no{" "}
        <a href="https://cancer-druginteractions.org/checker" target="_blank" rel="noreferrer noopener">
          <em>Cancer Drug Interactions</em>
        </a>
        , atualizado em junho de 2022, e consultado a 12/05/2023.
      </p>
      <p>
        Para obter detalhes espec&iacute;ficos e consultar a fonte original aceda a {" "}
        <a
          href="https://cancer-druginteractions.org/view_all_interactions/16853"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://cancer-druginteractions.org/view_all_interactions/16853
        </a>
      </p>
      <p>Para al&eacute;m disso, pode tamb&eacute;m consultar os seguintes RCMs:</p>
      <ul>
        <li className="mb-4">
          <strong>Abemaciclib</strong> - {" "}
          <a href="http://www.ema.europa.eu/en/documents/product-information/verzenios-epar-product-information_en.pdf">
            www.ema.europa.eu/en/documents/product-information/verzenios-epar-product-information_en.pdf
          </a>
        </li>
        <li className="mb-4">
          <strong>Palbociclib</strong> - {" "}
          <a href="http://www.ema.europa.eu/en/documents/product-information/ibrance-epar-product-information_en.pdf">
            www.ema.europa.eu/en/documents/product-information/ibrance-epar-product-information_en.pdf
          </a>
        </li>
        <li className="mb-4">
          <strong>Ribociclib </strong> - {" "}
          <a href="http://www.ema.europa.eu/en/documents/product-information/kisqali-epar-product-information_en.pdf">
            www.ema.europa.eu/en/documents/product-information/kisqali-epar-product-information_en.pdf
          </a>
        </li>
      </ul>
    </div>
  </>
);

export default References;
