import React from "react";
import "./App.css";

import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import Home from "pages/home";

import Login from "pages/login";
import AuthCallback from "pages/auth-callback";
import Groups from "pages/private/groups";
import Search from "pages/private/search";
import Layout from "components/base/layout";
import References from "pages/private/references";
import About from "pages/about";
import Help from "pages/help";
import { PatientContextProvider } from "data/patientContext";
import Patient from "pages/private/patient";
import HelpInstall from "pages/help-install";
import { initializeAnalytics } from "data/analytics";

export const RouteOptions = {
  "/": { title: "Início" },
  "/about": { title: "Sobre" },
  "/help": { title: "Como Funciona" },
  "/help-install": { title: "Instalar a app" },

  "/login": { title: "Login" },
  "/auth-callback": { title: "auth-callback" },

  "/private/search": { title: "Interações por Fámaco", showPatientView: true },
  "/private/groups": { title: "Grupos Farmacológicos", showPatientView: true },
  "/private/patient": { title: "Perfil de Doente", showPatientView: false },
  "/private/references": { title: "Referências" },
};

function App() {
  initializeAnalytics();

  return (
    <BrowserRouter>
      <PatientContextProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/help" element={<Help />} />
            <Route path="/help-install" element={<HelpInstall />} />

            <Route path="/login" element={<Login />} />
            <Route path="/auth-callback" element={<AuthCallback />} />

            <Route path="/private/search" element={<Search />} />
            <Route path="/private/groups" element={<Groups />} />
            <Route path="/private/patient" element={<Patient />} />
            <Route path="/private/references" element={<References />} />

            <Route
              path="*"
              element={
                <div className="d-flex align-items-center justify-content-center text-center pt-5 mt-5">
                  <h2>
                    Caminho inválido. <br />
                    Página não existente - Erro 404!
                  </h2>
                </div>
              }
            />
          </Routes>
        </Layout>
      </PatientContextProvider>
    </BrowserRouter>
  );
}

export default App;
