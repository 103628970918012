import React from "react";
import Groups from "components/groups";
import { useLocation } from "react-router-dom";

const GroupsPage = () => {
  const { state } = useLocation();

  const selectedGroup = state?.category;

  return (
    <>
      <h2 className="m-0 ms-lg-4 mb-4 text-start">Grupos Farmacológicos</h2>

      <Groups select={selectedGroup} />
    </>
  );
};

export default GroupsPage;
