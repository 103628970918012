import { useState } from "react";
import { DDIs, filterDDIs } from "data/ddis";
import DdiGroup from "components/ddi_group";
import { Button, Col, Row } from "react-bootstrap";
import ga4 from "react-ga4";
import DdiList from "components/ddi_list";
import { usePatientContext } from "data/patientContext";

const Patient = () => {
  const [curList, setCurList] = useState([]);
  const [filter, setFilter] = useState("");
  const [eventTimeout, setEventTimeout] = useState(0);

  const searchChange = e => {
    if (eventTimeout) clearTimeout(eventTimeout);
    setEventTimeout(
      setTimeout(() => {
        if (e.target.value?.trim() !== "") {
          ga4.event({
            category: "app-interaction",
            action: "drug-search-patient",
            label: e.target.value,
            search_term: e.target.value,
          });
        }
      }, 3000)
    );
    setFilter(e.target.value);
    if (e.target.value?.trim() !== "") {
      setCurList(filterDDIs(e.target.value));
    } else {
      setCurList([]);
    }
  };

  const patient = usePatientContext();

  const clear = () => {
    patient.clear();
  };

  return (
    <>
      <Row className="align-items-center mb-4">
        <Col>
          <h2 className="m-0 ms-lg-4  mb-lg-0 text-start">Perfil de Doente</h2>
        </Col>

        <Col xs="4" className="d-flex justify-content-end align-items-center">
          <div className="m-0 me-lg-4">
            <Button
              variant="light"
              className="d-flex align-items-center small"
              title="Limpar Perfil Doente"
              onClick={clear}
              style={{ fontSize: "12px" }}
            >
              Limpar<span className="d-none d-sm-inline">&nbsp;Perfil</span>
              <i className="bi bi-x ms-1" />
            </Button>
          </div>
        </Col>
      </Row>

      <div className="rounded m-0 m-lg-4 d-flex flex-row align-items-center justify-content-center patient-profile-card">
        {patient.drugList?.length > 0 ? (
          <div className="w-100">
            <DdiList list={patient.drugList} patientControls="remove,group" />
          </div>
        ) : (
          <div className="m-5 small fw-bold text-center">
            Pesquise e selecione abaixo os fármacos que deseja adicionar ao seu perfil de doente.
          </div>
        )}
      </div>

      {/* <hr className="m-5 mt-2 mb-2" /> */}
      <Row className="mt-4 mx-4  align-items-center">
        <Col xs="12" md="6" lg="5" className="d-flex justify-content-center">
          <div className="position-relative input-wrapper" style={{ width: "300px" }}>
            {/* <small className="text-muted">Pesquise por nome de fármaco:</small>
        <br /> */}
            <input
              type="text"
              placeholder="Pesquisa - Nome do fármaco..."
              value={filter}
              onChange={searchChange}
              style={{ color: "#222", width: "100%" }}
            />
            <span className="input-group-append">
              <i className="bi-search"></i>
            </span>
          </div>
        </Col>
        <Col xs="12" md="6" lg="7" className="mt-2 mt-md-0 small text-muted text-center text-md-start">
          Pesquise o nome do fármaco, selecione a linha, e utilize o ícone{" "}
          <span className="badge small rounded-pill bg-primary">
            <i className="bi bi-person-fill-add" />
          </span>{" "}
          para adicionar o fármaco ao seu perfil de doente
        </Col>
      </Row>

      {curList?.map((cat, idx) => (
        <div key={idx}>
          <DdiGroup group={cat} patientControls="add" />
        </div>
      ))}
      {(!curList || curList.length === 0) && filter.trim().length > 0 && (
        <div className="bg-light m-5 p-3 font-weight-bold rounded text-center">
          Nenhum fármaco encontrado para a sua pesquisa.
        </div>
      )}
    </>
  );
};

export default Patient;
