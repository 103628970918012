import Sponsors from "components/sponsors";
import React from "react";
import { Link } from "react-router-dom";

import PWAPrompt from "react-ios-pwa-prompt";
import ga4 from "react-ga4";
import { isInStandaloneMode } from "util";

const Home = () => (
  <div className="home text-center d-flex align-items-center justify-content-center flex-column w-100">
    <img
      src={require("img/logo_full.png")}
      alt="iCyclin - Ferramenta para análise de Interações Medicamentosas em iCDk4/6"
      height="auto"
      width="100%"
      className="mb-1 mt-lg-5 mb-lg-5"
      style={{ maxWidth: "500px" }}
    />

    <div className="d-flex justify-content-center mt-3 flex-column flex-lg-row">
      <div className="me-lg-2 mb-3 w-md-100">
        <Link to="/private/search">
          <div className="card-pink d-flex justify-content-center flex-row flex-lg-column">
            <img src={require("img/icons/Icone_Interacoes_Farmaco.png")} alt="" />
            Interações por fármaco
          </div>
        </Link>
      </div>
      <div className="mx-lg-2 mb-3">
        <Link to="/private/groups">
          <div className="card-pink d-flex justify-content-center flex-row flex-lg-column">
            <img src={require("img/icons/Icone_Grupos_Farmacologicos.png")} alt="" />
            Grupos Farmacológicos
          </div>
        </Link>
      </div>
      <div className="ms-lg-2 mb-3">
        <Link to="/private/patient">
          <div className="card-pink d-flex justify-content-center flex-row flex-lg-column">
            <img src={require("img/icons/Icone_Perfil_Doente.png")} alt="" />
            Perfil de <br className="d-none d-lg-inline"/>Doente
          </div>
        </Link>
      </div>
    </div>
    <Sponsors />

    <PWAPrompt
      timesToShow="5"
      copyTitle="Instale a app iCyclin"
      copyBody="Adicione esta aplicação ao seu ecrã principal para a poder encontrar rapidamente, utilizar mesmo que esteja sem rede e usar o ecrã inteiro, tal como uma app descarregada da AppStore."
      delay="2000"
      onClose={e => {
        console.log("User closed iOS install prompt");
        ga4.event({
          category: "app-interaction",
          action: "app-prompt-close",
          label: "ios prompt closed",
        });
      }}
      copyShareButtonLabel="Toque em 'Partilhar' no menu abaixo"
      copyAddHomeButtonLabel="Selecione 'Adicionar ao ecrã principal'"
      copyClosePrompt="Cancelar"
    ></PWAPrompt>
  </div>
);

export default Home;
