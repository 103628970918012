import { SupportBox } from "components/base/support";
import { DDILegendWithText } from "components/ddi_legend";
import { hcpGetAuthInfo, hcpIsLoggedIn, logoutHCP } from "data/hcp-auth";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { isInstalled } from "util";

const Help = () => {
  const lastHash = useRef("");
  const nav = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [showAppInstall, setShowAppInstall] = useState(false);

  useEffect(() => {
    setIsLoggedIn(hcpIsLoggedIn());
    setLoggedUser(hcpGetAuthInfo());

    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }
    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    isInstalled().then(res => {
      console.log("Checking instalation returned ", res);
      setShowAppInstall(!res);
    });
  }, []);

  return (
    <>
      <div className="text-center mt-5">
        <h2>Como Funciona</h2>
      </div>

      <div className="mx-lg-5 text-justified">
        <Row>
          <Col>
            <p>
              A plataforma <strong>iCyclin </strong>foi projetada para orientar os m&eacute;dicos sobre potenciais
              intera&ccedil;&otilde;es medicamentosas (DDIs) entre v&aacute;rios os grupos farmacol&oacute;gicos e os
              inibidores de ciclinas.
            </p>
            <p>
              Esta plataforma permite ao profissional de sa&uacute;de selecionar o melhor inibidor de CDK para a sua
              doente polimedicada ou, perante um doente que j&aacute; esteja em tratamento com um determinado inibidor
              CDK, perceber qual a sua intera&ccedil;&atilde;o com outros medicamentos/grupos farmacol&oacute;gicos.
            </p>
          </Col>
          {showAppInstall && (
            <Col
              xs="12"
              md="4"
              className="text-center border bg-light rounded p-3 app-install"
              onClick={() => nav("/help-install")}
              style={{ cursor: "pointer" }}
            >
              <h6 className="text-secondary fw-bold">Instale a app iCyclin</h6>

              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  className="text-center mt-2 mb-2"
                  src="/logo192.png"
                  width="60"
                  height="auto"
                  alt="iCyclin app logo"
                />
                <div className="small text-muted" style={{ fontSize: "12px" }}>
                  Clique para ver como pode adicionar a app iCyclin ao seu ecrã de entrada, de forma a ter um acesso
                  mais rápido e simples.
                </div>
              </div>
            </Col>
          )}
        </Row>
        <DDILegendWithText />
        <hr className="m-4" />
        <p>A análise das interações medicamentosas pode ser feita de 3 formas:</p>
        <ul>
          <li>Pesquisa por f&aacute;rmaco</li>
          <li>Pesquisa por grupo farmacol&oacute;gico</li>
          <li>Análise de perfil de doente</li>
        </ul>
        <h3 className="text-start mt-5">Pesquisa por F&aacute;rmaco</h3>
        <ul>
          <li>Pesquise o nome do F&aacute;rmaco - Digite o nome do medicamento na barra de pesquisa.</li>
          <li>
            Aparecer&aacute; o medicamento correspondente &agrave; sua pesquisa, e de que forma este medicamento
            interage com os inibidores de CDK.
          </li>
        </ul>
        <h3 className="text-start mt-5">Pesquisa por Grupo Farmacol&oacute;gico</h3>
        <ul>
          <li>
            Ao clicar no bot&atilde;o <em>Grupo Farmacol&oacute;gico</em> ir&atilde;o aparecer todos os grupos
            farmacol&oacute;gicos
          </li>
          <li>
            Selecione o grupo farmacol&oacute;gico que pretende verificar as intera&ccedil;&otilde;es medicamentosas.
          </li>
          <li>
            Ao selecionar um grupo farmacol&oacute;gico aparecer&aacute; uma lista com todos os f&aacute;rmacos desse
            grupo classificados de acordo com a sua seguran&ccedil;a ao interagir com inibidores de ciclinas.
          </li>
          <li>
            Para fechar este grupo, poderá clicar/tocar no cabeçalho do mesmo, ou simplesmente selecionar outro grupo.
          </li>
        </ul>

        <h3 className="text-start mt-5">Análise de Perfil de Doente</h3>
        <p>
          Esta aplicação permite-lhe também analisar um perfil de doente polimedicada, ficando com uma melhor perspetiva
          das diferentes interações medicamentosas numa doente em particular.
          <br />
        </p>
        <ul>
          <li>
            Selecione os medicamentos que pretende adicionar ao seu perfil de doente clicando no ícone{" "}
            <span className="badge fs-6 rounded-pill bg-primary">
              <i className="bi bi-person-fill-add" />
            </span>
            .
          </li>
          <li>
            Aparecerá uma caixa com o novo perfil de doente e fármacos selecionados
            <br />
            <img src={require("img/ajuda-perfil-doente.png")} height="200" width="auto" alt="exemplo pefil doente" />
          </li>
          <li>
            Caso pretenda remover um medicamento, após o ter adicionado ao seu perfil de doente, clique na linha do
            fármaco correspondente, no ícone{" "}
            <span className="badge fs-6 rounded-pill bg-primary">
              <i className="bi bi-x" />
            </span>{" "}
          </li>
          <li>
            Clicando no ícone{" "}
            <span className="badge fs-6 rounded-pill bg-primary">
              <i className="bi bi-arrow-left-right" />
            </span>
            , poderá encontrar uma alternativa com menos interações medicamentosas, dentro do mesmo grupo farmacológico.
          </li>
          <li className="mt-3">
            Ao fechar este perfil de doente, no ícone{" "}
            <span className="badge fs-6 rounded-pill bg-primary">
              <i className="bi bi-x" />
            </span>{" "}
            de topo, todos os fármacos serão removidos automaticamente. Poderá então iniciar uma nova seleção de
            fármacos.
          </li>
        </ul>
        <hr className="m-4 mt-5" />
        <div>
          <p>
            <strong>Estas funcionalidades são de utilização exclusiva para profissionais de saúde.</strong>
          </p>

          <p>
            Quando tentar aceder às mesmas, ser-lhe-á pedida informação (Nome, Profissão, Nº de cédula profissional) de
            forma a que possamos validar as suas credenciais perante a Ordem dos Médicos, Farmacêuticos ou Enfermeiros.
            Esta validação é efetuada uma única vez em cada dispositivo e os sseus dados são guardados exclusivamente no
            seu browser.
          </p>
          {isLoggedIn && (
            <div className="container">
              Tem já uma sessão ativa, com o seu registo validado com os seguintes dados:{" "}
              <ul>
                <li>
                  Nome: <strong>{loggedUser?.name}</strong>
                </li>
                <li>
                  Profissão: <strong>{loggedUser?.job}</strong>
                </li>
                <li>
                  Nº de Ordem: <strong>{loggedUser?.licenseNumber}</strong>
                </li>
              </ul>
              <p>Poderá terminar a sua sessão no botão abaixo:</p>
              <div>
                <Button
                  variant="outline-primary"
                  className="text-center"
                  onClick={() => {
                    if (
                      !window.confirm(
                        "Tem a certeza que deseja terrminar a sua sessão?\nTerá de voltar a validar os seus dados profissionais de forma a obter novamente acesso."
                      )
                    )
                      return;
                    logoutHCP();
                    nav("/");
                  }}
                >
                  Terminar Sessão
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr className="m-4 mt-5" id="support" />
      <div>
        <SupportBox />
      </div>
    </>
  );
};

export default Help;
