import { authenticateHCP } from "data/hcp-auth";
import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import ga4 from "react-ga4";
import { Link, useNavigate } from "react-router-dom";

const InternalLogin = () => {
  const [name, setName] = useState("");
  const [job, setJob] = useState("doctor");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const navigate = useNavigate();

  const nameChange = e => {
    setName(e.target.value);
  };

  const loginUser = e => {
    // Prevent Default to ensure form is not submitted (would cause failure to fetch)
    e.preventDefault();

    // State to update buttons
    setIsLoggingIn(true);

    console.log("Logging in with " + name + " " + job + " " + licenseNumber);
    ga4.event({
      category: "app-interaction",
      action: "login-submit",
      label: job,
      job,
    });

    authenticateHCP(job, name, licenseNumber).then(
      result => {
        if (result.isValid) {
          let redirectTo = sessionStorage.getItem("redirectTo") ?? "/";
          ga4.event({
            category: "app-interaction",
            action: "login-success",
            label: job,
            job,
          });
          navigate(redirectTo);

        } else {
          ga4.event({
            category: "app-interaction",
            action: "login-not-valid",
            label: job,
            message: result.validationMessage,
            licenseNumber
          });
          // State to update buttons
          setIsLoggingIn(false);
          alert(
            "Impossível validar os seus dados no " +
              result.datasource +
              ": " +
              result.validationMessage +
              "\n\nPor favor verifique e tente de novo."
          );
          setShowSupport(true);
        }
      },
      err => {
        ga4.event({
          category: "app-interaction",
          action: "login-error",
          label: job,
          message: err,
          licenseNumber
        });
        setIsLoggingIn(false);
        alert("Erro na validação: " + err);
        setShowSupport(true);
      }
    );
  };

  return (
    <div className="text-white d-flex align-items-center justify-content-center flex-column text-center mt-0 mt-md-5">
      <div className="bg-white rounded p-5 text-center">
        <h2 className="m-0 ms-lg-4  text-center">Acesso exclusivo a profissionais de saúde</h2>
        <p className="small text-muted text-center mb-4">
          Os seus dados profissionais serão validados na respetiva Ordem (dos Médicos, Farmacêuticos ou Enfermeiros).{" "}
          <br />
          Só terá de fazer esta validação uma única vez, neste dispositivo.
        </p>
        <div className="d-flex  align-items-center flex-column text-secondary">
          <Form onSubmit={loginUser}>
            <fieldset disabled={isLoggingIn} className="d-flex  align-items-center justify-content-center flex-column">
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={nameChange}
                  className="text-center"
                  style={{ width: "90vw", maxWidth: "400px" }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="job">
                <Form.Label>Profissão</Form.Label>
                <Form.Select
                  aria-label="Profissão"
                  value={job}
                  onChange={e => {
                    setJob(e.target.value);
                  }}
                  className="text-center"
                  style={{ width: "90vw", maxWidth: "400px" }}
                >
                  <option value="doctor">Médico/a</option>
                  <option value="nurse">Enfermeiro/a</option>
                  <option value="pharmacist">Farmacêutico/a</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="licenseNumber">
                <Form.Label>Nº de Ordem</Form.Label>
                <Form.Control
                  placeholder="_ _ _ _ _"
                  type="text"
                  value={licenseNumber}
                  maxLength={5}
                  onChange={e => {
                    setLicenseNumber(e.target.value);
                  }}
                  className="text-center"
                  style={{ width: "200px" }}
                />
              </Form.Group>
            </fieldset>
            <div className="mt-3">
              <Button type="submit" className="btn btn-primary btn-lg">
                {isLoggingIn ? (
                  <>
                    <Spinner
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                      as="span"
                      className="me-2"
                    ></Spinner>
                    A validar...
                  </>
                ) : (
                  "Validar"
                )}
              </Button>
            </div>
          </Form>
          {showSupport && (
            <div className="small text-muted text-center mt-4" style={{ maxWidth: "300px" }}>
              Em caso de dificuldade no acesso com os seus dados profissionais contacte o{" "}
              <Link to="/help#support">suporte</Link>.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default InternalLogin;
